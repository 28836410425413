export const ERROR_SOMETHING_WENT_WRONG = "LSH01";
export const ERROR_UNABLE_TO_LOAD_CART = "LSH02";
export const ERROR_UNABLE_TO_ADD_PRODUCT = "LSH03";
export const ERROR_UNABLE_TO_REMOVE_PRODUCT = "LSH04";
export const ERROR_UNABLE_TO_CHANGE_QUANTITY = "LSH05";
export const ERROR_UNABLE_TO_REMOVE_ITEMS = "LSH06";
export const ERROR_UNABLE_TO_CLEAR_CART = "LSH07";
export const ERROR_UNABLE_TO_EDIT_ITEM = "LSH08";
export const ERROR_UNABLE_TO_UPDATE_ATTRIBUTE = "LSH09";
export const ERROR_UNABLE_UPDATE_PAYMENT_METHOD = 'LSH10';
export const ERROR_UNABLE_UPDATE_ECO_PACKAGING = 'LSH11';
export const ERROR_UNABLE_TO_ADD_WISHLIST_ITEM_TO_CART = 'LSH12';
export const ERROR_INVALID_EMAIL = "LSH13";
export const ERROR_RESET_PASSWORD = "LSH14";
export const ERROR_UNABLE_LOAD_ORDER = "LSH15";
export const ERROR_UNABLE_TO_UPDATE_ADDRESS = "LSH16";
export const ERROR_UNABLE_TO_CANCEL_ADDRESS = "LSH17";
export const ERROR_UNABLE_UPDATE_CART_BUYER_IDENTITY = "LSH18";
export const ERROR_UNABLE_ADD_TO_CREATE_BOX = "LSH19";
export const ERROR_UNABLE_REMOVE_FROM_CREATE_BOX = "LSH20";
export const ERROR_UNABLE_UPDATE_CREATE_BOX = "LSH21";
export const ERROR_UNABLE_CHANGE_CREATE_BOX_FREQUENCY = "LSH22";
export const ERROR_UNABLE_ADD_TO_EDIT_BOX = "LSH23";
export const ERROR_UNABLE_REMOVE_FROM_EDIT_BOX = "LSH24";
export const ERROR_UNABLE_UPDATE_EDIT_BOX = "LSH25";
export const ERROR_UNABLE_CHANGE_EDIT_BOX_FREQUENCY = "LSH26";
export const ERROR_UNABLE_SAVE_CHANGES = "LSH27";
export const ERROR_NETWORK = "LSH28";

export const messages = {
    [ERROR_SOMETHING_WENT_WRONG]: 'Something went wrong. Please, report to Customer Support.',
    [ERROR_UNABLE_TO_LOAD_CART]: 'Unable to load cart. Please, report to Customer Support.',
    [ERROR_UNABLE_TO_ADD_PRODUCT]: 'Unable to add product to cart. Please, report to Customer Support.',
    [ERROR_UNABLE_TO_REMOVE_PRODUCT]: 'Unable to remove product from cart. Please, report to Customer Support.',
    [ERROR_UNABLE_TO_CHANGE_QUANTITY]: 'Unable to change quantity. Please, report to Customer Support.',
    [ERROR_UNABLE_TO_REMOVE_ITEMS]: 'Unable to remove items. Please, report to Customer Support.',
    [ERROR_UNABLE_TO_CLEAR_CART]: 'Unable to clear cart. Please, report to Customer Support.',
    [ERROR_UNABLE_TO_EDIT_ITEM]: 'Unable to edit item. Please, report to Customer Support.',
    [ERROR_UNABLE_TO_UPDATE_ATTRIBUTE]: 'Unable to update attributes. Please, report to Customer Support.',
    [ERROR_UNABLE_UPDATE_PAYMENT_METHOD]: 'Unable to update payment method.',
    [ERROR_UNABLE_UPDATE_ECO_PACKAGING]: 'Unable to update eco packaging.',
    [ERROR_UNABLE_TO_ADD_WISHLIST_ITEM_TO_CART]: 'Unable to add wishlist item to cart.',
    [ERROR_INVALID_EMAIL]: 'Email is invalid.',
    [ERROR_RESET_PASSWORD]: 'Reset password failed. Please, report to Customer Support.',
    [ERROR_UNABLE_LOAD_ORDER]: 'Unable to load order. Please, report to Customer Support.',
    [ERROR_UNABLE_UPDATE_CART_BUYER_IDENTITY]: 'Something went wrong. Please, report to Customer Support.',
    [ERROR_UNABLE_ADD_TO_CREATE_BOX]: 'Something went wrong. Please, report to Customer Support.',
    [ERROR_UNABLE_UPDATE_CREATE_BOX]: 'Something went wrong. Please, report to Customer Support.',
    [ERROR_UNABLE_CHANGE_CREATE_BOX_FREQUENCY]: 'Something went wrong. Please, report to Customer Support.',
    [ERROR_UNABLE_ADD_TO_EDIT_BOX]: 'Something went wrong. Please, report to Customer Support.',
    [ERROR_UNABLE_REMOVE_FROM_EDIT_BOX]: 'Something went wrong. Please, report to Customer Support.',
    [ERROR_UNABLE_UPDATE_EDIT_BOX]: 'Something went wrong. Please, report to Customer Support.',
    [ERROR_UNABLE_CHANGE_EDIT_BOX_FREQUENCY]: 'Something went wrong. Please, report to Customer Support.',
    [ERROR_UNABLE_SAVE_CHANGES]: 'Something went wrong. Please, report to Customer Support.',
    [ERROR_UNABLE_TO_UPDATE_ADDRESS]: 'Something went wrong during update address.',
    [ERROR_UNABLE_TO_CANCEL_ADDRESS]: 'Something went wrong during cancel',
    [ERROR_NETWORK]: 'Network error. Please check your internet connection'
};

export default messages;





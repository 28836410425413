import Vue from 'vue';
import Toast from "vue-toastification";

import {
    getCart,
    removeItem,
    changeQuantity,
    clearCart,
    update,
    updateAttributes,
    addItems,
    changeItems,
} from '@/api/cart';

import {
    ITEM_PROPERTY_PURCHASE_OPTION ,
    STATE_INITIALIZED,
    STATE_LOADING,
    STATE_CART,
    STATE_PRODUCTS,
    STATE_SIZES,
    STATE_SELECTED_ITEM,
    STATE_OVERLAY_SHOWN,
    GETTER_INITIALIZED,
    GETTER_LOADING,
    GETTER_CART,
    GETTER_USD_CART,
    GETTER_PRODUCTS,
    GETTER_SELECTED_ITEM,
    GETTER_SIZES,
    GETTER_HAS_ITEMS,
    GETTER_REQUIRES_SHIPPING,
    GETTER_OVERLAY_SHOWN,
    MUTATION_UPDATE_INITIALIZED,
    MUTATION_UPDATE_LOADING,
    MUTATION_UPDATE_CART,
    MUTATION_UPDATE_SELECTED_ITEM,
    MUTATION_UPDATE_SIZES,
    MUTATION_OVERLAY_SHOWN,
    MUTATION_UPDATE_PRODUCTS,
    ACTION_INIT_CART,
    ACTION_GET_CART,
    ACTION_SET_CART,
    ACTION_ADD_TO_CART,
    ACTION_REMOVE_ITEM,
    ACTION_REMOVE_ITEMS,
    ACTION_CHANGE_QUANTITY,
    ACTION_EDIT_ITEM,
    ACTION_UPDATE_SIZES,
    ACTION_UPDATE,
    ACTION_UPDATE_ATTRIBUTES,
    ACTION_CHANGE,
    ACTION_CLEAR,
    ACTION_ERROR,
    ACTION_SET_MINICART_LOADING,
    ACTION_SUCCESS,
    ITEM_PROPERTY_BUNDLE_PRODUCT,
    ITEM_PROPERTY_BUNDLE_KEY, GETTER_CART_WITH_PRODUCTS,
} from '@/store/minicart/shopify/cart/constants';

import {
    messages,
    ERROR_SOMETHING_WENT_WRONG,
    ERROR_UNABLE_TO_LOAD_CART,
    ERROR_UNABLE_TO_ADD_PRODUCT,
    ERROR_UNABLE_TO_REMOVE_PRODUCT,
    ERROR_UNABLE_TO_CHANGE_QUANTITY,
    ERROR_UNABLE_TO_REMOVE_ITEMS,
    ERROR_UNABLE_TO_CLEAR_CART,
    ERROR_UNABLE_TO_EDIT_ITEM,
    ERROR_UNABLE_TO_UPDATE_ATTRIBUTE, ERROR_NETWORK,
} from "@/helpers/error-codes";

import calculateHash from '@/helpers/calculate-hash';

import CloseIcon from "@/components/CloseIcon.vue";

import {
    MODULE_NAME as MODULE_CART,
    GETTER_CART_OPENED
} from '@/store/minicart/cart/constants';

import {
    MODULE_NAME as MODULE_CUSTOMER,
    GETTER_CUSTOMER
} from '@/store/minicart/shopify/customer/constants';

import {
    ITEM_PROPERTY_FREE_GIFT,
} from '@/store/minicart/free_gift/constants';

import {
    MODULE_NAME as MODULE_LUX_PRODUCT,
    GETTER_LUX_PRODUCT,
} from '@/store/minicart/lux_product/constants';

import {
    MODULE_NAME as MODULE_GLOBAL,
    GETTER_PAGE_UNLOADED,
} from '@/store/minicart/shopify/global/constants';

import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

import { getBulkProductsByIds } from "@/api/storefront";
import { EVENT_MINICART_ITEMS_COUNT, EVENT_MINICART_UPDATED } from '@/store/events';
import {
    PURCHASE_OPTION_SUBSCRIPTION,
} from '@/store/minicart/boxes/constants';

Vue.use(Toast, {
    transitionDuration: {
        enter: 1000,
        leave: 1000,
    },
    maxToasts: 1,
    newestOnTop: true,
    position: 'bottom-center',
    containerClassName: 'minicart-notifications',
});

export const DEFAULT_STATE = {
    [STATE_INITIALIZED]: false,
    [STATE_LOADING]: true,
    [STATE_CART]: {
        attributes: {},
        currency: 'USD',
        item_count: 0,
        items: [],
        requires_shipping: false,
        total_price: 0,
        total_weight: 0
    },
    [STATE_SELECTED_ITEM]: null,
    [STATE_SIZES]: [],
    [STATE_OVERLAY_SHOWN]: false,
    [STATE_PRODUCTS]: {}
};

const options = {
    timeout: 4000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: CloseIcon,
    icon: true,
    rtl: false,
    maxToasts: 1,
    newestOnTop: true,
};

const errorOptions = {
    toastClassName: [
        'minicart-toast',
        'minicart-toast--error'
    ],
    bodyClassName: [
        'minicart-toast__body',
        'minicart-toast__body--error'
    ],
    closeButtonClassName: [
        'minicart-toast__close',
        'minicart-toast__close--error'
    ],
};

const successOptions = {
    icon: true,
    toastClassName: [
        'minicart-toast',
        'minicart-toast--success'
    ],
    bodyClassName: [
        'minicart-toast__body',
        'minicart-toast__body--success'
    ],
    closeButtonClassName: [
        'minicart-toast__close',
        'minicart-toast__close--success'
    ],
};

export default {
    namespaced: true,
    state() {
        return {
            ...DEFAULT_STATE
        };
    },
    getters: {
        [GETTER_INITIALIZED](state) {
            return state[STATE_INITIALIZED];
        },
        [GETTER_PRODUCTS](state) {
            return state[STATE_PRODUCTS];
        },
        [GETTER_CART](state) {
            return state[STATE_CART] || DEFAULT_STATE[STATE_CART];
        },
        [GETTER_CART_WITH_PRODUCTS](state, getters, rootState, rootGetters) {
            const products = getters[GETTER_PRODUCTS];
            const cart = getters[GETTER_CART];

            const items = (cart?.items || [])?.map((item) => {
                const product_id = item?.product_id;
                if (!product_id) {
                    return {
                        ...item,
                        product: null,
                        variant: null,
                    };
                }

                const product = products[product_id];
                if (!product) {
                    return {
                        ...item,
                        product: null,
                        variant: null,
                    };
                }

                const variant_id = item?.variant_id;
                if (!variant_id) {
                    return {
                        ...item,
                        product,
                        variant: null,
                    };
                }

                const variants = product?.variants;
                if (!variants) {
                    return {
                        ...item,
                        product,
                        variant: null,
                    };
                }

                if (!variants?.length) {
                    return {
                        ...item,
                        product,
                        variant: null,
                    };
                }

                const variant = variants?.find((variant) => {
                    return variant?.id === variant_id;
                });

                if (!variant) {
                    return {
                        ...item,
                        product,
                        variant: null,
                    };
                }

                return {
                    ...item,
                    product,
                    variant,
                };
            })?.map((item) => {
                const { product } = item;
                if (!product) {
                    return item;
                }

                const lux_product = rootGetters[`${MODULE_LUX_PRODUCT}/${GETTER_LUX_PRODUCT}`];
                if (!lux_product) {
                    return item;
                }

                if (product?.id !== lux_product?.id) {
                    return item;
                }

                return {
                    ...item,
                    is_lux_key: true
                };
            })?.map((item) => ({
                ...item,
                hash: calculateHash(item)
            })) || [];

            return {
                ...cart,
                items
            };
        },
        [GETTER_USD_CART](state, getters) {
            const cart = getters[GETTER_CART_WITH_PRODUCTS];
            const { currency } = window.Shopify;
            if (!currency) {
                return cart;
            }

            const rate = parseFloat(currency?.rate);
            if (!rate) {
                return cart;
            }

            return {
                ...cart,
                items: (cart.items || []).map((item) => ({
                    ...item,
                    final_line_price: item.final_line_price
                        ? item.final_line_price / rate
                        : item.final_line_price,
                    final_price: item.final_price
                        ? item.final_price / rate
                        : item.final_price,
                    original_price: item.original_price
                        ? item.original_price / rate
                        : item.original_price,
                    unit_price: item.unit_price
                        ? item.unit_price / rate
                        : item.unit_price
                })),
                total_price: cart?.total_price
                    ? (cart?.total_price || 0) / rate
                    : cart?.total_price
            };
        },
        [GETTER_LOADING](state) {
            const initialized = state[STATE_INITIALIZED];
            if (!initialized) {
                return true;
            }

            return !!state[STATE_LOADING];
        },
        [GETTER_SELECTED_ITEM](state) {
            return state[STATE_SELECTED_ITEM];
        },
        [GETTER_SIZES](state) {
            return state[STATE_SIZES];
        },
        [GETTER_HAS_ITEMS](state, getters) {
            const cart = getters[GETTER_CART_WITH_PRODUCTS];
            if (!cart) {
                return false;
            }

            const { items } = cart;
            if (!items) {
                return false;
            }

            return Boolean(items.length);
        },
        [GETTER_REQUIRES_SHIPPING](state, getters) {
            const cart = getters[GETTER_CART_WITH_PRODUCTS];
            if (!cart) {
                return false;
            }

            const { items } = cart;
            if (!items) {
                return false;
            }

            if (!items?.length) {
                return false;
            }

            const item_that_requires_shipping = items?.find((item) => item?.requires_shipping === true);

            return Boolean(item_that_requires_shipping);
        },
        [GETTER_OVERLAY_SHOWN](state) {
            return state[STATE_OVERLAY_SHOWN] || false;
        }
    },
    mutations: {
        [MUTATION_UPDATE_CART](state, cart) {
            cart.item_count = Object.values(cart?.items || [])?.reduce((total, item) => total + item.quantity, 0) || 0;

            cart?.items?.forEach((item) => {
                if (item?.properties === null) {
                    item.filteredProperties = {};

                    return;
                }

                const properties = Object?.keys(item.properties);
                if (properties?.includes('_original_variant')) {
                    const original_variant = item?.properties?._original_variant;

                    item.url = original_variant?.url;
                }

                item.filteredProperties = properties.reduce((result, property) => {
                    if (!!property?.startsWith('_')) {
                        return result;
                    }

                    if ([
                        'shipping_interval_unit_type',
                        'shipping_interval_frequency',
                        ITEM_PROPERTY_BUNDLE_PRODUCT,
                        ITEM_PROPERTY_BUNDLE_KEY
                    ]?.includes(property)) {
                        return result;
                    }

                    result[property] = item.properties[property];

                    return result;
                }, {});

                if (![
                    !!properties?.includes('shipping_interval_unit_type'),
                    !!properties?.includes('shipping_interval_frequency')
                ]?.includes(false)) {
                    item.filteredProperties.Subscription = `${item.properties.shipping_interval_frequency} ${item.properties.shipping_interval_unit_type}`;
                }
            });

            state[STATE_CART] = cart;
        },
        [MUTATION_UPDATE_INITIALIZED](state, initialized) {
            state[STATE_INITIALIZED] = initialized;
        },
        [MUTATION_UPDATE_LOADING](state, loading) {
            state[STATE_LOADING] = loading;
        },
        [MUTATION_UPDATE_SELECTED_ITEM](state, item) {
            state[STATE_SELECTED_ITEM] = item;
        },
        [MUTATION_UPDATE_SIZES](state, sizes) {
            state[STATE_SIZES] = sizes;
        },
        [MUTATION_OVERLAY_SHOWN](state, overlay_shown = false) {
            state[STATE_OVERLAY_SHOWN] = overlay_shown;
        },
        [MUTATION_UPDATE_PRODUCTS](state, products) {
            state[STATE_PRODUCTS] = products;
        }
    },
    actions: {
        async [ACTION_INIT_CART](context, {
            successCallback = () => {},
        } = {
            successCallback: () => {},
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            try {
                context.commit(MUTATION_UPDATE_INITIALIZED, false);

                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: true
                });

                await context.dispatch(ACTION_GET_CART);

                context.commit(MUTATION_UPDATE_INITIALIZED, true);

                if (typeof successCallback !== "function") {
                    return;
                }

                await successCallback();
            } catch (error) {

            } finally {
                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: false
                });
            }
        },
        async [ACTION_GET_CART](context, {
            successCallback = () => {},
            errorCallback = () => {}
        } = {
            successCallback: () => {},
            errorCallback: () => {}
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            await context.dispatch(ACTION_SET_MINICART_LOADING, {
                loading: true
            });

            try {
                const cart = await getCart({
                    params: {
                        force: true,
                    }
                });

                await context.dispatch(ACTION_SET_CART, {
                    cart
                });

                const customer = context.getters[`${MODULE_CUSTOMER}/${GETTER_CUSTOMER}`];

                document.dispatchEvent(new CustomEvent(EVENT_MINICART_UPDATED, {
                    detail: {
                        cart: context.getters[GETTER_CART_WITH_PRODUCTS],
                        customer
                    }
                }));

                if (typeof successCallback !== "function") {
                    return;
                }

                await successCallback();
            } catch (error) {
                if (error.name === "FetchFailedDuringPageUnloadError") {
                    return;
                }

                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Unable to fetch cart`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}

                await errorCallback(error);

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_LOAD_CART);
            } finally {
                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: false
                });
            }
        },
        async [ACTION_SET_CART](context, {
            cart,
        }) {
            let cart_products = context.state[STATE_PRODUCTS];
            const product_ids = Object?.keys(cart_products)?.map((key) => {
                return key?.toString();
            }) || [];

            const missed_product_ids = [
                ...new Set((cart?.items || [])?.map((item) => {
                    return item?.product_id;
                }) || []),
            ]?.filter((id) => {
                return !product_ids?.includes(`${id}`);
            }) || [];

            if (!!missed_product_ids?.length) {
                try {
                    const fetched_products = (await getBulkProductsByIds(missed_product_ids, {
                        identifiers: [],
                        variant_identifiers: [
                            {
                                namespace: 'gwp',
                                key: 'enabled'
                            }
                        ]
                    }, {
                        id: true,
                        availableForSale: true,
                        createdAt: false,
                        description: false,
                        descriptionHtml: false,
                        featuredImage: true,
                        handle: true,
                        isGiftCard: true,
                        onlineStoreUrl: false,
                        options: {
                            id: false,
                            name: true,
                            values: true,
                        },
                        productType: false,
                        publishedAt: false,
                        requiresSellingPlan: false,
                        sellingPlanGroups: false,
                        seo: false,
                        tags: true,
                        title: true,
                        totalInventory: false,
                        updatedAt: false,
                        vendor: false,
                        images: false,
                        media: false,
                        variants: {
                            availableForSale: true,
                            barcode: false,
                            compareAtPrice: true,
                            id: true,
                            image: false,
                            price: false,
                            quantityAvailable: true,
                            requiresShipping: false,
                            selectedOptions: true,
                            sku: false,
                            title: true,
                            weight: false,
                            weightUnit: false,
                            metafields: true,
                        },
                        metafields: false,
                    }))?.reduce((products, product) => {
                        const id = product?.id;
                        if (!id) {
                            return products;
                        }

                        return {
                            ...products,
                            [id]: product,
                        };
                    }, {}) || {};

                    cart_products = {
                        ...(cart_products || {}),
                        ...fetched_products,
                    };

                    if (!!fetched_products) {
                        context.commit(MUTATION_UPDATE_PRODUCTS, cart_products);
                    }
                } catch (error) {
                    try {
                        Bugsnag.notify(new Error(`[${PREFIX}] Fetch cart missed products error`), (event) => {
                            event.severity = 'error';

                            event.addMetadata('parsedError', {
                                error,
                                missed_product_ids,
                                response: error?.data || error?.response,
                            });
                        });
                    } catch (error) {}
                }
            }

            context.commit(MUTATION_UPDATE_CART, cart);

            document.dispatchEvent(new CustomEvent(EVENT_MINICART_ITEMS_COUNT, {
                detail: {
                    item_count: cart?.item_count || 0,
                }
            }));
        },

        async [ACTION_ADD_TO_CART](context, {
            products,
            successCallback = () => {},
            errorCallback = () => {},
        } = {
            products: [],
            successCallback: () => {},
            errorCallback: () => {},
            overlay: false,
            overlay_duration: 0,
            success: true,
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            await context.dispatch(ACTION_SET_MINICART_LOADING, {
                loading: true
            });

            if (!products) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Empty product array was passed into Minicart`), (event) => {
                        event.severity = 'error';
                    });
                } catch (error) {}

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_ADD_PRODUCT);

                return;
            }

            if (!products?.length) {
                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Empty product array was passed into Minicart`), (event) => {
                        event.severity = 'error';
                    });
                } catch (error) {}

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_ADD_PRODUCT);

                return;
            }

            console.log("cart", {
                products,
            });

            try {
                const cart = context.getters[GETTER_CART_WITH_PRODUCTS];

                const {
                    productsToAdd,
                    productsToUpdate,
                } = (products || [])?.map((product) => {
                    const properties = product?.properties || {};

                    if (properties[ITEM_PROPERTY_PURCHASE_OPTION] !== PURCHASE_OPTION_SUBSCRIPTION) {
                        delete product.selling_plan;
                    }

                    return product;
                })?.reduce((result, product) => {
                    const item = cart?.items?.find((item) => {
                        const calculated_hash = calculateHash(product);
                        if (!calculated_hash) {
                            return false;
                        }

                        return item?.hash === calculated_hash;
                    });

                    const properties = product?.properties || {};

                    if (!item) {
                        return {
                            ...result,
                            productsToAdd: [
                                ...(result?.productsToAdd || []),
                                {
                                    ...product,
                                    ...(properties ? {
                                        properties: Object?.keys(properties)?.reduce((filtered_properties, key) => {
                                            const value = properties[key];
                                            if (!value) {
                                                return filtered_properties;
                                            }

                                            return {
                                                ...filtered_properties,
                                                [key]: value
                                            };
                                        }, {}) || {}
                                    } : {})
                                }
                            ]
                        };
                    }

                    return {
                        ...result,
                        productsToUpdate: [
                            ...(result?.productsToUpdate || []),
                            {
                                ...product,
                                id: item.key,
                                quantity: Object?.keys(properties || {})?.includes(ITEM_PROPERTY_FREE_GIFT) ? 1 : (
                                    parseInt(item.quantity) + parseInt(product.quantity)
                                ),
                                ...(properties ? {
                                    properties: {
                                        ...(Object?.keys(properties)?.filter((key) => {
                                            return ![]?.includes(key);
                                        })?.reduce((filtered_properties, key) => {
                                            return {
                                                ...filtered_properties,
                                                [key]: properties[key]
                                            };
                                        }, {}) || {}),
                                    }
                                } : {})
                            }
                        ]
                    };
                },
                {
                    productsToAdd: [],
                    productsToUpdate: [],
                }) || {
                    productsToAdd: [],
                    productsToUpdate: [],
                };

                if (!!productsToAdd?.length) {
                    await addItems(productsToAdd);
                }

                if (!!productsToUpdate?.length) {
                    await changeItems(productsToUpdate);
                }

                document.dispatchEvent(new CustomEvent('track:cart:added', {
                    detail: {
                        products: productsToAdd
                    }
                }));

                document.dispatchEvent(new CustomEvent('track:cart:updated', {
                    detail: {
                        products: productsToUpdate
                    }
                }));

                if (typeof successCallback !== "function") {
                    return;
                }

                await successCallback();
            } catch (error) {
                if (error.name === "FetchFailedDuringPageUnloadError") {
                    return;
                }

                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Unable add product to cart`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_ADD_PRODUCT);

                await errorCallback(error);
            } finally {
                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: false
                });
            }
        },
        async [ACTION_REMOVE_ITEM](context, {
            key,
            successCallback = () => {},
            errorCallback = () => {},
        } = {
            successCallback: () => {},
            errorCallback: () => {},
            success: true,
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            await context.dispatch(ACTION_SET_MINICART_LOADING, {
                loading: true
            });

            try {
                const cart = context.getters[GETTER_CART_WITH_PRODUCTS];

                const item = cart?.items?.find((item) => {
                    return item.key === key;
                });

                await removeItem(key);

                document.dispatchEvent(new CustomEvent('track:cart:removed', {
                    detail: {
                        items: [item]
                    }
                }));

                const lux_product =
                    context.rootGetters[
                        `${MODULE_LUX_PRODUCT}/${GETTER_LUX_PRODUCT}`
                    ];
                if (
                    lux_product &&
                    item.product_id &&
                    lux_product.id === item.product_id
                ) {
                    document.dispatchEvent(new CustomEvent('track:cart:membership:removed', {
                        detail: {
                            membership: false,
                            items: [item]
                        }
                    }));
                }

                if (typeof successCallback !== "function") {
                    return;
                }

                await successCallback();
            } catch (error) {
                if (error.name === "FetchFailedDuringPageUnloadError") {
                    return;
                }

                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error during remove item`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.response,
                        });
                    });
                } catch (error) {}

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_REMOVE_PRODUCT);

                await errorCallback(error);
            } finally {
                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: false
                });
            }
        },
        async [ACTION_CHANGE_QUANTITY](context, {
            key,
            quantity,
            successCallback = () => {},
            errorCallback = () => {},
        } = {
            successCallback: () => {},
            errorCallback: () => {},
            success: true,
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            await context.dispatch(ACTION_SET_MINICART_LOADING, {
                loading: true
            });

            try {
                await changeQuantity(key, quantity);

                const item = context.state[STATE_CART].items.find((item) => {
                    return item.key === key;
                });

                document.dispatchEvent(new CustomEvent('track:cart:changed-quantity', {
                    detail: {
                        item
                    }
                }));

                if (typeof successCallback !== "function") {
                    return;
                }

                await successCallback();
            } catch (error) {
                if (error.name === "FetchFailedDuringPageUnloadError") {
                    return;
                }

                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error during change item quantity`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_CHANGE_QUANTITY);

                await errorCallback(error);
            } finally {
                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: false
                });
            }
        },
        async [ACTION_REMOVE_ITEMS](context, {
            keys = [],
            successCallback = () => {},
            errorCallback = () => {}
        } = {
            keys: [],
            successCallback: () => {},
            errorCallback: () => {},
            success: true
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            if (!keys) {
                return;
            }

            if (!keys?.length) {
                return;
            }

            try {
                const cart = context.state[GETTER_CART_WITH_PRODUCTS];

                const items = cart?.items?.filter((item) => {
                    return !!keys?.includes(item?.key);
                }) || [];

                await context.dispatch(ACTION_UPDATE, {
                    updates: keys?.reduce((keys, key) => ({
                        ...keys,
                        [key]: 0
                    }), {}) || {},
                    errorCallback
                });

                document.dispatchEvent(new CustomEvent('track:cart:removed', {
                    detail: {
                        items
                    }
                }));

                if (typeof successCallback !== "function") {
                    return;
                }

                await successCallback();
            } catch (error) {
                if (error.name === "FetchFailedDuringPageUnloadError") {
                    return;
                }

                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error during remove items`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            keys,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_REMOVE_ITEMS);

                await errorCallback(error);
            } finally {
                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: false
                });
            }
        },
        [ACTION_EDIT_ITEM](context, { item }) {
            context.commit(MUTATION_UPDATE_SELECTED_ITEM, item);
        },
        async [ACTION_CLEAR](context, {
            successCallback = () => {},
            errorCallback = () => {},
        } = {
            successCallback: () => {},
            errorCallback: () => {},
            success: true,
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            await context.dispatch(ACTION_SET_MINICART_LOADING, {
                loading: true
            });

            try {
                await clearCart();

                if (typeof successCallback !== "function") {
                    return;
                }

                await successCallback();
            } catch (error) {
                if (error.name === "FetchFailedDuringPageUnloadError") {
                    return;
                }

                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error during clear items`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_CLEAR_CART);

                await errorCallback(error);
            } finally {
                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: false
                });
            }
        },
        async [ACTION_UPDATE](context, {
            updates,
            successCallback = () => {},
            errorCallback = () => {},
        } = {
            successCallback: () => {},
            errorCallback: () => {},
            success: true,
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            await context.dispatch(ACTION_SET_MINICART_LOADING, {
                loading: true
            });

            try {
                await update(updates);

                if (typeof successCallback !== "function") {
                    return;
                }

                await successCallback();
            } catch (error) {
                if (error.name === "FetchFailedDuringPageUnloadError") {
                    return;
                }

                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error during update items`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_EDIT_ITEM);

                await errorCallback(error);
            } finally {
                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: false
                });
            }
        },
        async [ACTION_UPDATE_ATTRIBUTES](context, {
            attributes,
            successCallback = () => {},
            errorCallback = () => {},
        } = {
            successCallback: () => {},
            errorCallback: () => {},
            success: true,
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            await context.dispatch(ACTION_SET_MINICART_LOADING, {
                loading: true
            });

            try {
                await updateAttributes(attributes);

                if (typeof successCallback !== "function") {
                    return;
                }

                await successCallback();
            } catch (error) {
                if (error.name === "FetchFailedDuringPageUnloadError") {
                    return;
                }

                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error during update attributes`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_UPDATE_ATTRIBUTE);

                await errorCallback(error);
            } finally {
                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: false
                });
            }
        },
        async [ACTION_CHANGE](context, {
            key,
            item,
            successCallback = () => {},
            errorCallback = () => {},
        } = {
            successCallback: () => {},
            errorCallback: () => {},
            success: true,
        }) {
            if (!window?.navigator?.onLine) {
                return;
            }

            await context.dispatch(ACTION_SET_MINICART_LOADING, {
                loading: true
            });

            try {
                await context.dispatch(ACTION_ADD_TO_CART, {
                    products: [item],
                    success: false,
                });

                await context.dispatch(ACTION_REMOVE_ITEM, {
                    key,
                    success: false,
                });

                if (typeof successCallback !== "function") {
                    return;
                }

                await successCallback();
            } catch (error) {
                if (error.name === "FetchFailedDuringPageUnloadError") {
                    return;
                }

                try {
                    Bugsnag.notify(new Error(`[${PREFIX}] Error during change item`), (event) => {
                        event.severity = 'error';

                        event.addMetadata('parsedError', {
                            error,
                            response: error?.data || error?.response,
                        });
                    });
                } catch (error) {}

                await context.dispatch(ACTION_ERROR, ERROR_UNABLE_TO_EDIT_ITEM);

                await errorCallback(error);
            } finally {
                await context.dispatch(ACTION_SET_MINICART_LOADING, {
                    loading: false
                });
            }
        },
        [ACTION_UPDATE_SIZES](context, { sizes = [] } = { sizes: [] }) {
            context.commit(MUTATION_UPDATE_SIZES, sizes);
        },
        [ACTION_SUCCESS](context, {
            message = 'Success',
            when_minicart_opened = true,
        } = {}) {
            if (!window?.navigator?.onLine) {
                return;
            }

            Vue.$toast.clear();

            const opened = context.rootGetters[`${MODULE_CART}/${GETTER_CART_OPENED}`];
            if (!!when_minicart_opened && !opened) {
                return;
            }

            Vue.$toast.success(message, {
                ...(options || {}),
                ...(successOptions || {}),
                ...(opened ? {
                    position: 'top-center'
                } : {
                    position: 'bottom-center'
                }),
                maxToasts: 1,
            } || {});
        },
        [ACTION_ERROR](context, error_code = ERROR_SOMETHING_WENT_WRONG) {
            if (document.visibilityState === 'hidden') {
                return;
            }

            if (!!context.rootGetters[`${MODULE_GLOBAL}/${GETTER_PAGE_UNLOADED}`]) {
                return;
            }

            const opened = context.rootGetters[`${MODULE_CART}/${GETTER_CART_OPENED}`];

            const error = `${error_code}: ${messages[error_code] || messages[ERROR_SOMETHING_WENT_WRONG]}`;

            Vue.$toast.error(error, {
                ...(options || {}),
                ...(errorOptions || {}),
                ...(opened ? {
                    position: 'top-center'
                } : {
                    position: 'bottom-center'
                }),
                maxToasts: 1,
                ...(error_code === ERROR_NETWORK ? {
                    timeout: 0
                } : {}),
            } || {});
        },
        [ACTION_SET_MINICART_LOADING](context, {
            loading = false
        } = {
            loading: false
        }) {
            context.commit(MUTATION_UPDATE_LOADING, loading);
        },
    },
};
